import React, { useState, useCallback } from "react";
import axios from "axios";

const App: React.FC = () => {
  const [sourceFiles, setSourceFiles] = useState<File[]>([]);
  const [result, setResult] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFolderSelect = useCallback(async () => {
    try {
      // Check if the File System Access API is supported
      if ("showDirectoryPicker" in window) {
        const dirHandle = await (window as any).showDirectoryPicker();
        const files: File[] = [];

        for await (const entry of dirHandle.values()) {
          if (entry.kind === "file" && entry.name.endsWith(".csv")) {
            const file = await entry.getFile();
            files.push(file);
          }
        }

        setSourceFiles(files);
      } else {
        alert(
          "Your browser does not support folder selection. Please use the fallback method."
        );
      }
    } catch (error) {
      console.error("Error selecting folder:", error);
      alert("Error selecting folder. Please try again.");
    }
  }, []);

  const handleSourceFilesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setSourceFiles(sourceFiles.concat(Array.from(event.target.files)));
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (sourceFiles.length === 0) {
      alert("Please select at least one source file");
      return;
    }

    setIsLoading(true);
    setResult("");

    const formData = new FormData();

    sourceFiles.forEach((file) => {
      formData.append("sourceFiles", file);
    });

    try {
      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // setResult(response.data);
      if (response.status === 200) {
        const contentDisposition = response.headers["content-disposition"];
        const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
        const filename = filenameMatch ? filenameMatch[1] : "result.csv";

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(new Blob([response.data]));
        downloadLink.setAttribute("download", filename);
        downloadLink.click();
      } else {
        setResult(response.data);
      }
    } catch (error: any) {
      console.error("Error uploading files:", error);
      setResult(
        `Error processing files: ${error.response?.data || error.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      <h1 className="title">Experiment CSV helper</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="waveLength" className="label">
            Prefered wavelength (nm) (default: 202.9000):
          </label>
          <input
            type="number"
            id="waveLength"
            name="waveLength"
            defaultValue="202.9000"
            step="0.1"
            required
            className="input"
          />
        </div>
        <div className="form-group">
          <label className="label">
            Source CSV files (with "data" column(s)):
          </label>
          <button type="button" onClick={handleFolderSelect} className="button">
            Select Folder
          </button>
          <input
            type="button"
            id="addFiles"
            value="Add additional CSV files"
            onClick={() => document.getElementById("sourceFiles")?.click()}
          />
          <input
            type="file"
            id="sourceFiles"
            accept=".csv"
            title="Add additional CSV files"
            multiple
            onChange={handleSourceFilesChange}
            style={{ display: "none" }}
            className="input"
          />
          <p className="file-count">Selected files: {sourceFiles.length}</p>
          {sourceFiles.length > 0 && (
            <ul className="file-list">
              {sourceFiles.map((file) => (
                <li key={file.name} className="file-item">
                  {file.name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button type="submit" disabled={isLoading} className="button">
          {isLoading ? "Processing..." : "Process Files"}
        </button>
      </form>
      {result && (
        <div className="result">
          <h2 className="result-title">Result:</h2>
          <pre className="result-content">{result}</pre>
        </div>
      )}
    </div>
  );
};

export default App;
